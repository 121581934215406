import * as React from 'react';

import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { LazyImage } from 'components/LazyImage';

const Wrapper = styled.div`
  .title {
    margin-bottom: 24px;
    ${fromScreen(776)} {
      margin-bottom: 40px;
      text-align: center;
    }
  }
`;

const SectionInfo = styled.div`
  .banner-image {
    order: -1;
  }
  display: grid;
  place-items: center;
  gap: 24px;
  .problem-list {
    display: grid;
    gap: 24px;
    .problem-item {
      border: 1px solid ${colorsV2.gray20};
      border-radius: 20px;
      display: grid;
      gap: 8px;
      padding: 16px;
      .icon > img {
        width: 32px;
      }
    }
  }

  ${fromScreen(458)} {
    .headline {
      font-size: 20px;
      line-height: 28px;
    }
  }

  ${fromScreen(776)} {
    .problem-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .problem-item:not(:last-child) {
        width: 344px;
      }
      .problem-item:last-child {
        width: 480px;
      }
    }
  }

  ${fromScreen(1144)} {
    grid-template-columns: 540px 1fr;
    place-items: center;
    gap: 60px;
    .problem-list {
      grid-template-columns: unset;
      .problem-item {
        width: auto !important;
        padding: 20px;
        .icon > img {
          width: 40px;
        }
      }
    }
    .banner-image {
      order: 1;
    }
  }
`;

const crash = [
  {
    title: 'Bạn cần chứng chỉ IELTS gấp trong vòng 2-3 tháng',
    imageHandle: '4OYKzHSNTkWRHefNvNVy',
    desc: 'Bạn cần điểm IELTS để ra trường, để hoàn thành hồ sơ du học hay định cư nhưng chỉ còn vài tháng để chuẩn bị.'
  },
  {
    title: 'Bạn không muốn mất quá nhiều thời gian cho việc học IELTS?',
    imageHandle: 'gFvhhgTgRTurf5K9iLTw',
    desc: 'Bạn muốn tập trung toàn bộ thời gian vào học IELTS trong vòng 2-3 tháng cho thật giỏi và sau đó không cần phải lo lắng về nó nữa.'
  },
  {
    title:
      'Bạn muốn nhanh chóng lấy chứng chỉ IELTS nhưng vẫn đảm bảo được khả năng sử dụng Tiếng Anh trong giao tiếp cũng như học tập?',
    imageHandle: 'ofdJoE92Tj60VTjouh63',
    desc: 'Bạn muốn được học 1 phương pháp Tư duy thông minh giúp mình tiến bộ nhanh chóng nhưng vẫn đảm bảo giỏi về thực chất chứ không phải học mẹo, học tủ hay học thuộc lòng bài mẫu.'
  }
];
const basic = [
  {
    title: 'Bạn mất căn bản và sợ học Tiếng Anh',
    imageHandle: 'jreyeCfKQWmCtg9xmBuP',
    desc: 'Bạn đã học Tiếng Anh nhiều năm nhưng nền tảng cơ bản vẫn chưa vững. Cảm giác việc học hết sức mơ hồ và không biết bao giờ mới giỏi.'
  },
  {
    title:
      'Bạn có vốn từ vựng và ngữ pháp cơ bản nhưng khi áp dụng thực tế thì thường sai cơ bản',
    imageHandle: 'OGzKw9QJRhmdzq1ZPnub',
    desc: 'Bạn thường dịch, ghép từng từ tiếng Việt sang tiếng Anh dẫn đến câu cú lủng củng nên không thể phát triển 1 đoạn hoàn chỉnh và liên kết.'
  },
  {
    title:
      'Bạn muốn nhanh chóng lấy lại căn bản đảm bảo được khả năng sử dụng Tiếng Anh trong giao tiếp, học tập và lấy chứng chỉ IELTS trong tương lai?',
    imageHandle: 'ofdJoE92Tj60VTjouh63',
    desc: 'Bạn muốn được học 1 phương pháp Tư duy thông minh giúp mình tiến bộ nhanh chóng nhưng vẫn đảm bảo giỏi về thực chất chứ không phải học mẹo, học tủ hay học thuộc lòng bài mẫu.'
  }
];

const advance = [
  {
    title: 'Bạn bị mắc kẹt ở band 5-6 thậm chí 7 rất lâu',
    imageHandle: '4OYKzHSNTkWRHefNvNVy',
    desc: 'Dù tự học rất nhiều nhưng bạn vẫn bị kẹt ở band 5-6 trong 1 thời gian dài, không thể cải thiện được dù đã áp dụng rất nhiều mẹo làm bài.'
  },
  {
    title: 'Bạn thường gặp khó khăn khi gặp chủ đề lạ và khó',
    imageHandle: 'pXCNdfTmS9y2OS6sdnfj',
    desc: 'Bạn có vốn từ vựng và ngữ pháp tương đối ổn, có thể xử lý tốt trong giao tiếp và các chủ đề quen thuộc nhưng khi gặp chủ đề lạ bạn thường không hiểu hoặc không thể nghĩ ra ý tưởng để phát triển.'
  },
  {
    title:
      'Bạn muốn nhanh chóng lấy chứng chỉ IELTS nhưng vẫn đảm bảo được khả năng sử dụng Tiếng Anh trong giao tiếp cũng như học tập?',
    imageHandle: 'ofdJoE92Tj60VTjouh63',
    desc: 'Bạn muốn được học 1 phương pháp Tư duy thông minh giúp mình tiến bộ nhanh chóng nhưng vẫn đảm bảo giỏi về thực chất chứ không phải học mẹo, học tủ hay học thuộc lòng bài mẫu.'
  }
];

const problemDesc = {
  advance,
  basic,
  crash
};

const Problem = ({ level }) => {
  return (
    <Wrapper>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        className="title"
        as="h2"
      >
        Bạn có đang gặp phải những vấn đề sau?
      </Typography>
      <SectionInfo>
        <div className="problem-list">
          {problemDesc[level].map(({ title, imageHandle, desc }) => (
            <div className="problem-item" key={imageHandle}>
              <LazyImage
                handle={imageHandle}
                className="icon"
                alt="icon-problem"
              />
              <Typography
                variant="semi-bold/16-24"
                v3
                className="headline"
                as="h3"
              >
                {title}
              </Typography>
              <Typography variant="regular/14-20" color={colorsV2.black80}>
                {desc}
              </Typography>
            </div>
          ))}
        </div>
        <div className="banner-image">
          <LazyImage
            handle="B1rpkhEZRC2LOkLcXbb5"
            alt="Các vấn đề khi học IELTS"
          />
        </div>
      </SectionInfo>
    </Wrapper>
  );
};

export default React.memo(Problem);
