import styled from 'styled-components/macro';
import { colorsV2 } from 'style/colors-v2';
import { shadows } from 'style/shadows';
import { fromScreen } from 'utils/media-query/responsive.util';

const Common = styled.div`
  height: max-content;
  position: relative;
  .tag {
    cursor: pointer;
    position: absolute;
    padding: 8px 12px;
    background: ${colorsV2.white100};
    border-radius: 8px;
    border: 1px solid ${colorsV2.gray20};
    box-shadow: ${shadows.zIndex2};
    transition: all 0.5s;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background: ${colorsV2.white100};
      border-right: 1px solid ${colorsV2.gray20};
      border-bottom: 1px solid ${colorsV2.gray20};
      transition: all 0.3s;
    }
    &:nth-child(2) {
      &::after {
        bottom: -6px;
        right: 20px;
        transform: rotate(45deg);
      }
    }
    &:nth-child(3) {
      &::after {
        bottom: -6px;
        left: 20px;
        transform: rotate(45deg);
      }
    }
    &:nth-child(4) {
      &::after {
        top: -6px;
        right: 20px;
        transform: rotate(-135deg);
      }
    }
    &:nth-child(5) {
      &::after {
        top: -6px;
        left: 20px;
        transform: rotate(-135deg);
      }
    }

    &:hover {
      border: 1px solid ${colorsV2.primary100};
      color: ${colorsV2.primary100};
      &::after {
        border-right: 1px solid ${colorsV2.primary100};
        border-bottom: 1px solid ${colorsV2.primary100};
      }
    }

    &.active {
      border: 1px solid ${colorsV2.primary100};
      color: ${colorsV2.primary100};
      background: ${colorsV2.primary10};
      &::after {
        background: ${colorsV2.primary10};
        border-right: 1px solid ${colorsV2.primary100};
        border-bottom: 1px solid ${colorsV2.primary100};
      }
    }
  }
`;

export const Banner = styled(Common)`
  .tag {
    &.crash {
      &:nth-child(2) {
        top: 77px;
        left: 0;
      }
      &:nth-child(3) {
        top: 8px;
        right: 0;
      }

      &:nth-child(4) {
        bottom: 13px;
        left: 22px;
      }
      &:nth-child(5) {
        bottom: 56px;
        right: 16px;
      }
    }

    &.basic {
      &:nth-child(2) {
        top: 39px;
        left: 0;
      }
      &:nth-child(3) {
        top: 8px;
        right: 20px;
      }

      &:nth-child(4) {
        bottom: 57px;
        left: 12px;
      }
      &:nth-child(5) {
        bottom: 24px;
        right: 8px;
      }
    }

    &.advance {
      &:nth-child(2) {
        top: 60px;
        left: 11px;
      }
      &:nth-child(3) {
        top: 10px;
        right: 12px;
      }

      &:nth-child(4) {
        bottom: 81px;
        left: 0;
      }
      &:nth-child(5) {
        bottom: 15px;
        right: 0;
      }
    }
  }

  ${fromScreen(776)} {
    .tag {
      &.crash {
        &:nth-child(2) {
          top: 97px;
          left: 33px;
        }
        &:nth-child(3) {
          top: 33px;
          right: 9px;
        }

        &:nth-child(4) {
          bottom: 15px;
          left: 106px;
        }
        &:nth-child(5) {
          bottom: 90px;
          right: 37px;
        }
      }
      &.basic {
        &:nth-child(2) {
          top: 71px;
          left: 21px;
        }
        &:nth-child(3) {
          top: 23px;
          right: 50px;
        }

        &:nth-child(4) {
          bottom: 135px;
          left: 14px;
        }
        &:nth-child(5) {
          bottom: 95px;
          right: 5px;
        }
      }
      &.advance {
        &:nth-child(2) {
          top: 91px;
          left: 43px;
        }
        &:nth-child(3) {
          top: 26px;
          right: 33px;
        }

        &:nth-child(4) {
          bottom: 138px;
          left: 30px;
        }
        &:nth-child(5) {
          bottom: 74px;
          right: 16px;
        }
      }
    }
  }
`;
