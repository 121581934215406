import * as React from 'react';
import cl from 'classnames';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { colorsV2 } from 'style/colors-v2';
import { Difference } from 'shared/CourseLanding/DifferencesHome';
import { fromScreen } from 'utils/media-query/responsive.util';
import { Banner } from '../section/style';

const Wrapper = styled.div`
  display: grid;
  gap: 24px;
  place-items: center;

  ${fromScreen(1144)} {
    grid-template-columns: 540px 1fr;
    gap: 28px;
    place-items: unset;
  }
`;
const Differences = styled.div`
  width: 100%;
  padding: 52px 0;
  .button-text {
    margin-top: 18px;
  }
  .panel-body {
    > div {
      ${fromScreen(458)} {
        max-width: 393px;
      }
      ${fromScreen(776)} {
        max-width: 680px;
      }
      ${fromScreen(1144)} {
        max-width: 480px;
      }
    }
  }
`;

const Main = styled.div`
  .title {
    margin-bottom: 24px;
    ${fromScreen(776)} {
      margin-bottom: 40px;
    }
  }
`;

export const IeltsDifference = ({ data, level }) => {
  const [selected, setSelected] = React.useState(1);
  return (
    <Main>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        className="title"
        as="h2"
      >
        Với Linearthinking, DOL sẽ giúp bạn
      </Typography>
      <Wrapper>
        <Banner>
          <LazyImage
            handle={data.bannerHandle}
            alt="Khác biệt của Linearthinking"
          />
          {data.differences
            .sort((diff1, diff2) => diff1.order - diff2.order)
            .map(diff => (
              <Typography
                key={diff.order}
                variant="semi-bold/14-20"
                tabletVariant="semi-bold/16-24"
                className={cl('tag', level, {
                  active: selected === diff.order
                })}
                color={colorsV2.black60}
                onClick={() => setSelected(diff.order)}
                v3
              >
                {diff.tag}
              </Typography>
            ))}
        </Banner>
        <Differences>
          {data.differences
            .sort((diff1, diff2) => diff1.order - diff2.order)
            .map(diff => (
              <Difference
                selected={selected}
                key={diff.order}
                diff={diff}
                setSelected={() => setSelected(diff.order)}
              />
            ))}
        </Differences>
      </Wrapper>
    </Main>
  );
};
