import * as React from 'react';

import Layout from 'shared/CourseIeltsDetail/Layout';
import IeltsNangCaoDifferencesMain from 'shared/CourseIeltsDetail/section/IeltsNangCaoDifferencesMain';
import { Course } from 'shared/CourseIeltsDetail/section/Course';
import Problem from 'shared/CourseIeltsDetail/section/Problem';

const ieltsDesc =
  'Nhiều bạn bị chững lại ở giai đoạn này, làm hoài không tăng điểm. Cái cần nhất lúc này là Tư Duy để tận dụng tối đa kiến thức sẵn có và bứt phá đạt điểm mong muốn. Hay nói vắn tắt “Học Tư duy chấp hết đề, tư duy ít Vocab đọc vẫn hiểu” của Linearthinking.';

const CoursePage = ({ location }) => {
  return (
    <Layout
      slug={location.pathname}
      title="IELTS"
      highlight="nâng cao"
      ieltsDesc={ieltsDesc}
      featureImg="yInUqz8eSFSyrn7buz0u"
      seoTitle="Tổng hợp khóa học IETLS nâng cao giúp tăng band nhanh chóng"
      seoDesc="Khóa học IELTS nâng cao tại DOL English ứng dụng Linearthinking giúp học viên nâng cao ngữ pháp, từ vựng và khả năng dùng tiếng Anh giúp tăng band điểm hiệu quả"
    >
      <Problem level="advance" />
      <IeltsNangCaoDifferencesMain />
      <Course
        title="Các khóa IELTS nâng cao tại DOL"
        level="Khóa IELTS nâng cao"
      />
    </Layout>
  );
};

export default React.memo(CoursePage);
